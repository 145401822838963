@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Figtree', sans-serif;  /* ✅ Apply Figtree globally */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Figtree', sans-serif; /* ✅ Apply Figtree to code elements too */
}

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;  /* ✅ Prevent scrolling */
  width: 100vw;
  height: 100vh;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.loading-spin {
  animation: spin 4s linear infinite;
}